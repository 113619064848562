import React, { Component } from "react";
import { Layout } from "../components/Layout";
import { SEO } from "../components/SEO";
import { SubmissionState } from "../renderProps/ClientAuthenticationGuard";
import { CookieGuard } from "../renderProps/CookieGuard";
import ReactGA from "react-ga";

interface IProps {}

interface IState {
    password: string;
    feedbackType?: string;
    feedbackMessage?: string;
}

class AuthenticatePage extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = { password: "" };
    }

    public render() {
        return (
            <CookieGuard
                render={({ submitAuthentication }) => {
                    const { feedbackMessage, feedbackType, password } = this.state;

                    const onSubmit = async (event: React.SyntheticEvent) => {
                        event.preventDefault();
                        const submissionState = await submitAuthentication({ password: this.state.password });
                        if (submissionState === SubmissionState.Passed) {
                            ReactGA.event({
                                category: "authentication",
                                action: "succeeded",
                                label: "Authentication Succeeded",
                            });
                            this.setState({
                                feedbackType: undefined,
                                feedbackMessage: undefined,
                            });
                            window.location.href = "/";
                        } else {
                            ReactGA.event({
                                category: "authentication",
                                action: "failed",
                                label: "Authentication Failed",
                            });
                            this.setState({
                                feedbackType: "error",
                                feedbackMessage: "The password you entered was incorrect! Please try again.",
                            });
                        }
                    };
                    const onInputChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
                        this.setState({ password: event.currentTarget.value });
                    };

                    return (
                        <Layout>
                            <SEO title="Authenticate" meta={[]} lang={"en-au"} />
                            <h2>Enter your password</h2>
                            <div className={"width-container side-padding"}>
                                <form onSubmit={onSubmit} className={"authenticate"}>
                                    <input type={"password"} value={password} onChange={onInputChange} placeholder={""} />
                                    <button type={"submit"}>Log In</button>
                                    {feedbackType ? <div className={`feedback ${feedbackType}`}>{feedbackMessage}</div> : ""}
                                </form>
                            </div>
                        </Layout>
                    );
                }}
            />
        );
    }
}

export default AuthenticatePage;
